<template>
  <v-container fluid grid-list-lg>
    <Create
      :createDialog="createDialog"
      @close="createDialog = false"
      @submit="getList"
    ></Create>
    <Edit
      :editDialog="editDialog"
      @close="editDialog = false"
      @submit="getList"
      :editDepartment="selectedDepartment"
    ></Edit>
    <v-card :loading="loading" outlined>
      <ListTitle
        :showBackButton="true"
        title="Department"
        @add="add"
      />

      <v-card-title>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="search"
              outlined
              prepend-inner-icon="search"
              rounded
              dense
              label="Search"
              single-line
              hide-details
              clearable
            ></v-text-field
          ></v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="Departments"
        :search="search"
        :loading="loading"
        :sort-by="sortBy"
        :page.sync="page"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:item="{ item }">
          <tr class="pointer">
            <td class="text-left">{{ item.name }}</td>
            <td class="justify-end align-center text-right">
              <v-menu bottom left offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon>
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list class="menu-button">
                  <v-list-item
                    @click="editDepartment(item)"
                  >
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="deleteDepartment(item)"
                  >
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
        <v-alert slot="no-results" :value="true" color="error" icon="warning"
          >Your search for "{{ search }}" found no results.</v-alert
        >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import departmentService from "@/modules/Product/Department/service.js";

const Create = () => import("./Create");
const Edit = () => import("./Edit");
export default {
  name: "department-list",
  data() {
    return {
      createDialog: false,
      editDialog: false,
      selectedDepartment: null,
      headers: [
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "",
          value: "action",
          sortable: false,
        },
      ],
      Departments: [],
      search: null,
      loading: true,
      page: 1,
      sortBy: "name",
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  created() {
    this.getList();
    document.title = "smplsale - Department List";
  },
  components: {
    Create,
    Edit,
  },
  methods: {
    add() {
      this.createDialog = !this.createDialog;
    },
    getList() {
      this.loading = true;
      this.editDialog = false;
      this.createDialog = false;
      return departmentService
        .getAll()
        .then((response) => {
          this.loading = false;
          this.Departments = response.data;
          return response;
        });
    },
    editDepartment(department) {
      this.selectedDepartment = department;
      this.editDialog = !this.editDialog;
    },
    deleteDepartment(department) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          departmentService.delete(department.id).then((response) => {
            if (response.data) {
              this.$swal("Deleted!", "Department has been deleted.", "success");
              this.getList();
            }
          });
        }
      });
    },
  },
};
</script>
